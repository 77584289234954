import { css } from '@emotion/core'
import React, { useContext } from 'react'

import { Html, ItemHeading, ResponsiveImage } from '..'
import { ThemeColorContext } from '../../contexts'
import {
  desktopBreakpoint,
  largeText,
  linkTextLineStyle,
  makeContentStyle,
  mobileLargerBreakpoint,
  textContentLineLength
} from '../../styles'
import { ResponsiveImageData } from '../../types'
import { SlantedText } from '../type'
import { Parallax } from 'react-scroll-parallax'

const style = {
  wrapper: css`
    display: block;
    text-decoration: none;
    margin-bottom: 4em;
    position: relative;
    @media ${mobileLargerBreakpoint} {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
    }
    @media ${desktopBreakpoint} {
      margin-bottom: 8em;
    }
  `,
  imageWrapper: css`
    position: relative;
    z-index: -3;

    @media ${mobileLargerBreakpoint} {
      margin-right: 2em;
      width: 25vw;
      height: 25vw;
      flex: 1;
    }
    @media ${desktopBreakpoint} {
      position: absolute;
    }
  `,
  image: css`
    @media ${mobileLargerBreakpoint} {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      .gatsby-image-wrapper {
        max-height: 100%;
        img {
          object-fit: contain !important;
        }
      }
    }
  `,
  contentWrapper: css`
    flex: 2;
    @media ${desktopBreakpoint} {
      padding-left: 33%;
      padding-top: 33%;
    }
  `,
  title: css`
    @media ${desktopBreakpoint} {
      margin-left: 0;
      margin-top: 0;
    }
  `,
  summary: css`
    max-width: ${textContentLineLength};
  `
}

export interface SupporterTeaserProps {
  title: string[]
  path: string
  image: ResponsiveImageData
  summary: string
  suffix?: (string | unknown)[] | false
  prefix?: (string | unknown)[] | false
  index: number
}

export const SupporterTeaser = ({
  summary,
  path,
  title,
  image,
  prefix,
  suffix,
  index
}: SupporterTeaserProps) => {
  const themeColor = useContext(ThemeColorContext)
  return (
    <a href={path} css={[style.wrapper, linkTextLineStyle]}>
      {image && (
        <div css={style.imageWrapper}>
          <div css={style.image}>
            <Parallax y={[-20, 20]}>
              <ResponsiveImage image={image} alt={'Beitragsbild: ' + title.join(' ')} />
            </Parallax>
          </div>
        </div>
      )}
      <div css={[style.contentWrapper, makeContentStyle(themeColor)]}>
        <div css={style.title}>
          <ItemHeading
            colorIndex={index % 3}
            titleSuffix={suffix}
            titlePrefix={prefix}
            title={title}
            size={0.75}
          />
        </div>
        <SlantedText responsive={true}>
          <Html css={[largeText, style.summary]}>{summary}</Html>
        </SlantedText>
      </div>
    </a>
  )
}

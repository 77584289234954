import { css, Global } from '@emotion/core'
import React, { useContext } from 'react'

import { MenuLink, MobileMenuButton } from '..'
import config from '../../../config.json'
import {
  LocationContext,
  MobileMenuContext,
  SectionContext,
  ThemeColorContext
} from '../../contexts'
import { desktopBreakpoint, getContrastColor, makeBgStyle } from '../../styles'
import pathEqual from '../../utils/pathEqual'

const style = {
  mobileMenuButton: css`
    position: absolute;
    top: 0;
    right: 0;
    @media ${desktopBreakpoint} {
      display: none;
    }
  `,
  mobileMenu: css`
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: space-around;
    align-items: center;
    > ul {
    }
    &[aria-expanded='true'] {
      display: flex;
    }
    @media ${desktopBreakpoint} {
      display: block;
      position: relative;
      background: transparent;
    }
  `,
  mobileMenuOpen: css``,
  menu: css`
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    @media ${desktopBreakpoint} {
      font-size: 1.5em;
      width: auto;
    }
  `,
  nonJsGlobalStyles: css`
    body:not(.has-js) {
      .Menu--not-first {
        display: none;
        @media ${desktopBreakpoint} {
          display: flex;
        }
      }
      .Menu--first {
        position: relative;
        display: block;
        @media ${desktopBreakpoint} {
          position: relative;
        }
      }
    }
  `
}

export const Menu = () => {
  const themeColor = useContext(ThemeColorContext)
  const currPath = useContext(LocationContext)
  const index = useContext(SectionContext)
  const { openMobileMenu, setOpenMobileMenu } = useContext(MobileMenuContext)
  const menuId = `menu-${Math.random()
    .toString(36)
    .substr(2, 9)}`
  const thisMenuIsOpen = openMobileMenu === index
  const handleToggleMenu = () => setOpenMobileMenu(openMobileMenu === index ? null : index)

  return (
    <>
      <Global styles={[style.nonJsGlobalStyles]} />
      <nav>
        <div css={style.mobileMenuButton} className="hide-without-js">
          <MobileMenuButton
            color={getContrastColor(themeColor)}
            menuId={menuId}
            isOpen={thisMenuIsOpen}
            onClick={handleToggleMenu}
          />
        </div>
        <div
          css={[
            makeBgStyle(themeColor),
            style.mobileMenu,
            thisMenuIsOpen ? style.mobileMenuOpen : ''
          ]}
          id={menuId}
          aria-expanded={thisMenuIsOpen}
          className={index !== 0 ? 'Menu--not-first' : 'Menu--first'}
        >
          <ul role="menu" css={style.menu} aria-labelledby={`button-menu-${menuId}`}>
            {Object.keys(config.pathMap).map((label, key) => (
              <MenuLink
                label={label}
                path={(config.pathMap as any)[label]}
                key={key}
                color={themeColor}
                isActive={pathEqual(currPath, (config.pathMap as any)[label])}
              />
            ))}
          </ul>
        </div>
      </nav>
    </>
  )
}

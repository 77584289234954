import React from 'react'
import isSSR from '../../utils/isSSR'

const encode = (str: string): string => str.replace('@', isSSR() ? '__at__' : '@')

interface EmailLinkProps {
  email: string
  label?: string
}
export const EmailLink = ({ email, label }: EmailLinkProps) => {
  const encodedEmail = encode(email)
  const emailHref = `mailto:${encodedEmail}`
  const content = label ? encode(label) : encodedEmail
  return (
    <>
      {isSSR() && (
        <>
          <small>
            Ersetzen Sie das __at__ durch das @-Zeichen, um die Email-Adresse zu erhalten:
          </small>
          &nbsp;
        </>
      )}
      <a href={emailHref}>{content}</a>
    </>
  )
}

import { graphql, StaticQuery } from 'gatsby'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import config from '../../../config.json'
import { LocationContext } from '../../contexts'

interface SEOProps {
  title?: string
  description?: string
  image?: string
  article?: boolean
}

export const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
      }
    }
  }
`

export const SEO = ({ title, description, image, article }: SEOProps) => {
  const currPath = useContext(LocationContext)
  const url = config.siteUrl + currPath + '/'
  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: { defaultTitle, defaultDescription }
        }
      }) => {
        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: `${config.siteUrl}${image || config.defaultImage}`,
          url: url
        }
        return (
          <>
            <Helmet title={seo.title} titleTemplate={`%s | ${config.shortName}`}>
              <meta name="description" content={seo.description} />
              <meta name="image" content={seo.image} />
              {seo.url && <meta property="og:url" content={seo.url} />}
              {(article ? true : null) && <meta property="og:type" content="article" />}
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.description && <meta property="og:description" content={seo.description} />}
              {seo.image && <meta property="og:image" content={seo.image} />}
              <meta name="twitter:card" content="summary_large_image" />
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && <meta name="twitter:description" content={seo.description} />}
              {seo.image && <meta name="twitter:image" content={seo.image} />}
            </Helmet>
          </>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false
}

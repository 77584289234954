import { css } from '@emotion/core'
import React from 'react'

import { Container, FooterLogo } from '..'
import { desktopBreakpoint, getColor, makeMenuLinkStyle, menuLinkStyle } from '../../styles'
import makePath from '../../utils/makePath'

const style = {
  wrapper: css`
    background: ${getColor('grey')};
    color: ${getColor('white')};
    padding-top: 1em;
    padding-bottom: 1em;
    overflow: hidden;
    a {
      color: ${getColor('white')};
    }
  `,
  row: css`
    @media ${desktopBreakpoint} {
      align-items: center;
      display: flex;
      flex-direction: row;
    }
  `,
  left: css`
    flex: 1;
    margin-bottom: 2em;
    @media ${desktopBreakpoint} {
      margin-bottom: 0;
    }
  `,
  right: css`
    flex: 1;
    @media ${desktopBreakpoint} {
      max-width: 14em;
      display: flex;
      flex-direction: row-reverse;
      img {
        margin: 0;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  `,
  link: css`
     {
      @media ${desktopBreakpoint} {
        margin-right: 2em;
      }
    }
  `
}
export const Footer = () => (
  <div css={style.wrapper}>
    <Container>
      <div css={style.row}>
        <div css={style.left}>
          <a
            href={makePath('kontakt')}
            title="Kontakt"
            css={[menuLinkStyle, makeMenuLinkStyle('grey'), style.link]}
          >
            Kontakt
          </a>
          <a
            href={makePath('beratung')}
            title="Beratung"
            css={[menuLinkStyle, makeMenuLinkStyle('grey'), style.link]}
          >
            Beratung
          </a>
          <a
            href={makePath('solidarity')}
            title="English"
            css={[menuLinkStyle, makeMenuLinkStyle('grey'), style.link]}
          >
            English
          </a>
          <a
            href={makePath('dayanisma')}
            title="Dayanışma"
            css={[menuLinkStyle, makeMenuLinkStyle('grey'), style.link]}
          >
            Dayanışma
          </a>
        </div>
        <div css={style.right}>
          <a css={menuLinkStyle} href="https://www.rote-hilfe.de" title="Rote Hilfe e.V.">
            <FooterLogo />
          </a>
        </div>
      </div>
    </Container>
  </div>
)

import { css } from '@emotion/core'
import React from 'react'

import { Container } from '..'
import {
  getHeadingColor,
  linkTextLineStyle,
  makeBgStyle,
  mobileLargerBreakpoint
} from '../../styles'
import { ThemeColorName } from '../../types'
import makePath from '../../utils/makePath'
import { TextLine } from './TextLine'
import { TextLineBlock } from './TextLineBlock'

const style = {
  wrapper: css`
    text-align: center;
    padding-top: 1.5em;
    padding-bottom: 2em;
    font-size: 1.33em;
    overflow: hidden;
  `,
  row: css`
    @media ${mobileLargerBreakpoint} {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
    }
  `,
  col: css`
    display: block;
    flex: 1;
    margin-bottom: 2em;
    &:last-of-type {
      margin-bottom: 0;
    }
    @media ${mobileLargerBreakpoint} {
      margin-bottom: 0;
    }
  `
}

interface CTAProps {
  color: ThemeColorName
  showMitmachen: boolean
  showBeratung: boolean
}
export const CTA = ({ color, showMitmachen, showBeratung }: CTAProps) => {
  // use the logo first color for this color as contrast
  const backgroundColor = color
  // use the heading colors for the background color
  const colorTheme1 = getHeadingColor(backgroundColor, 0)
  const colorTheme2 = getHeadingColor(backgroundColor, 1)
  const colorTheme3 = getHeadingColor(backgroundColor, 2)
  const colorTheme4 = getHeadingColor(backgroundColor, 3)
  const colorTheme5 = getHeadingColor(backgroundColor, 4)
  const colorTheme6 = getHeadingColor(backgroundColor, 5)
  return (
    <div css={[makeBgStyle(backgroundColor), style.wrapper]}>
      <Container>
        <div css={style.row}>
          {showMitmachen && (
            <a
              css={[linkTextLineStyle, style.col]}
              href={makePath('mitmachen')}
              title="Werde Mitglied und unterstütze unsere Arbeit!"
            >
              <TextLineBlock>
                <TextLine
                  fontFamily="druk text wide"
                  fontWeight={900}
                  size={0.66}
                  spacing={-0.025}
                  color={colorTheme1[0]}
                  background={colorTheme1[1]}
                  xOffset={10}
                >
                  Werde Mitglied
                </TextLine>
                <TextLine xOffset={-5} color={colorTheme2[0]} background={colorTheme2[1]}>
                  und unterstütze
                </TextLine>
                <TextLine xOffset={33} color={colorTheme3[0]} background={colorTheme3[1]}>
                  unsere Arbeit!
                </TextLine>
              </TextLineBlock>
            </a>
          )}
          {showBeratung && (
            <a
              css={[linkTextLineStyle, style.col]}
              href={makePath('beratung')}
              title="Beratungstermine der Rote Hilfe Ortsgruppen"
            >
              <TextLineBlock>
                <TextLine
                  spacing={-0.025}
                  color={colorTheme4[0]}
                  background={colorTheme4[1]}
                  xOffset={10}
                >
                  Beratungstermine
                </TextLine>
                <TextLine
                  size={0.66}
                  fontFamily="druk text wide"
                  fontWeight={900}
                  xOffset={-10}
                  color={colorTheme5[0]}
                  background={colorTheme5[1]}
                >
                  der Rote Hilfe
                </TextLine>
                <TextLine xOffset={50} color={colorTheme6[0]} background={colorTheme6[1]}>
                  Ortsgruppen
                </TextLine>
              </TextLineBlock>
            </a>
          )}
        </div>
      </Container>
    </div>
  )
}

CTA.defaultProps = {
  showMitmachen: true,
  showBeratung: false
}

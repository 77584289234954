import React from 'react'

import { NonStretchedImage } from '..'
import {
  exclusiveDesktopBreakpoint,
  exclusiveMobileBreakpoint,
  exclusiveDesktopLargeBreakpoint
} from '../../styles'
import { ResponsiveImageData } from '../../types'

interface ResponsiveImageProps {
  image: ResponsiveImageData
  desktopImage?: ResponsiveImageData
  alt: string
}

/**
 * Render a fluid responsive image, render a desktop version of the image if it
 * is available.
 */
export const ResponsiveImage = ({ image, desktopImage, alt }: ResponsiveImageProps) => {
  // initial media sources
  const sources = {
    image: image.mobile.fluid,
    desktop: image.desktop ? image.desktop.fluid : image.mobile.fluid,
    desktopLarge: image.desktopLarge
      ? image.desktopLarge.fluid
      : image.desktop
      ? image.desktop.fluid
      : image.mobile.fluid
  }

  // desktop sources
  if (desktopImage) {
    sources.desktop = desktopImage.desktop ? desktopImage.desktop.fluid : sources.desktop
    sources.desktopLarge = desktopImage.desktopLarge
      ? desktopImage.desktopLarge.fluid
      : sources.desktopLarge
  }

  const fluid = [
    {
      ...sources.image,
      media: exclusiveMobileBreakpoint
    },
    {
      ...sources.desktop,
      media: exclusiveDesktopBreakpoint
    },
    {
      ...sources.desktopLarge,
      media: exclusiveDesktopLargeBreakpoint
    }
  ]
  return <NonStretchedImage alt={alt} fluid={fluid} />
}

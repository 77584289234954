import { css, Global } from '@emotion/core'
import { debounce } from 'lodash'
import React, { PropsWithChildren } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'

import { CTA, ErrorBoundary, Footer } from '..'
import { LocationContext, MobileMenuContext, LanguageContext } from '../../contexts'
import { globalStyles } from '../../global-styles'
import { Maybe } from '../../graphql-types'
import { desktopBreakpoint, footerHeight } from '../../styles'
import { ThemeColorName, Language } from '../../types'
import isSSR from '../../utils/isSSR'

interface RenderData {
  readonly site: {
    readonly siteMetadata: {
      readonly title: string
    }
  }
}

interface LayoutProps {
  currPath: string
  language: Language
  pageColor: ThemeColorName
}

interface LayoutState {
  openMobileMenu: Maybe<number>
  hideFooter: boolean
}

const style = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  content: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    @media ${desktopBreakpoint} {
      padding-bottom: ${footerHeight};
    }
  `,
  footer: css`
    @media ${desktopBreakpoint} {
      position: fixed;
      top: auto;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999;
      height: ${footerHeight};
      transition: transform 300ms linear;
    }
  `,
  footerHidden: css`
    @media ${desktopBreakpoint} {
      transform: translateY(100%);
    }
  `
}

const getScrollPos = () => (isSSR() ? 0 : window.pageYOffset)
const getWindowHeight = () => (isSSR() ? 0 : window.innerHeight)
const getDocHeight = () =>
  isSSR()
    ? 0
    : // @see https://j11y.io/javascript/get-document-height-cross-browser/
      Math.max(
        window.document.body.scrollHeight,
        window.document.documentElement.scrollHeight,
        window.document.body.offsetHeight,
        window.document.documentElement.offsetHeight,
        window.document.body.clientHeight,
        window.document.documentElement.clientHeight
      )

export class Layout extends React.Component<PropsWithChildren<LayoutProps>, LayoutState> {
  static defaultProps = {
    language: 'de'
  }

  scrollPos: number
  docHeight: number

  constructor(props: PropsWithChildren<LayoutProps>) {
    super(props)

    this.state = {
      openMobileMenu: null,
      hideFooter: false
    }

    this.handleSetOpenMobileMenu = this.handleSetOpenMobileMenu.bind(this)
    this.handleScroll = debounce(this.handleScroll.bind(this), 0)
    this.scrollPos = getScrollPos()
    this.docHeight = getDocHeight()
  }

  componentDidMount() {
    if (!isSSR()) {
      window.addEventListener('scroll', this.handleScroll, { passive: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const currScrollPos = Math.floor(getScrollPos())
    const currWindowHeight = getWindowHeight()
    const currDocHeight = getDocHeight()
    const isAtBottom = currScrollPos + currWindowHeight + 5 >= currDocHeight
    this.setState({
      hideFooter: this.scrollPos < currScrollPos && !isAtBottom
    })
    this.scrollPos = currScrollPos
  }

  handleSetOpenMobileMenu(val: Maybe<number>) {
    this.setState({
      openMobileMenu: val
    })
  }

  render() {
    const { children, currPath, pageColor, language } = this.props
    const { openMobileMenu, hideFooter } = this.state
    const showMitmachen = currPath.replace(/\//g, '') !== 'mitmachen'
    const showBeratung = currPath.replace(/\//g, '') !== 'beratung'
    return (
      <ErrorBoundary>
        <Global styles={globalStyles} />
        <LanguageContext.Provider value={language}>
          <LocationContext.Provider value={currPath}>
            <MobileMenuContext.Provider
              value={{ openMobileMenu, setOpenMobileMenu: this.handleSetOpenMobileMenu }}
            >
              <div css={style.wrapper}>
                <div css={style.content}>
                  <ParallaxProvider>{children}</ParallaxProvider>
                  <CTA
                    color={pageColor}
                    showBeratung={showBeratung}
                    showMitmachen={showMitmachen}
                  />
                </div>
                <div css={[style.footer, hideFooter ? style.footerHidden : '']}>
                  <Footer />
                </div>
              </div>
            </MobileMenuContext.Provider>
          </LocationContext.Provider>
        </LanguageContext.Provider>
      </ErrorBoundary>
    )
  }
}

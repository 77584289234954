import { css } from '@emotion/core'
import React from 'react'

import { colors } from '../../styles'

const barHeight = 4
const barSpace = 8
const barWidth = barHeight * 3 + barSpace * 3

const style = {
  menuButton: (color: ColorName) => css`
    appearance: none;
    background: transparent;
    border: 0;
    display: block;
    z-index: 9;
    position: relative;
    cursor: pointer;
    padding: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: 0;
    }
    &:focus .inner:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.3;
      background-color: ${colors[color]};
      outline: 10px solid ${colors[color]};
    }
    .inner {
      display: block;
      position: relative;
      height: ${barHeight * 3 + barSpace * 2}px;
      width: ${barWidth}px;
    }
    .bar {
      z-index: 10;
      display: flex;
      position: absolute;
      width: ${barWidth}px;
      height: ${barHeight}px;
      background: ${colors[color]};
      z-index: 1;
      transform-origin: 0% 50%;
      transform: translate3d(0, 0);
      transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.3s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.3s ease;
    }
    .bar:nth-of-type(1) {
      top: 0;
    }
    .bar:nth-of-type(2) {
      top: ${barHeight + barSpace}px;
    }
    .bar:nth-of-type(3) {
      top: ${2 * barHeight + 2 * barSpace}px;
    }
    &[aria-expanded='true'] {
      .bar:nth-of-type(1) {
        transform: rotate(45deg) translate(4px, -5px);
      }
      .bar:nth-of-type(2) {
        opacity: 0;
        transform: scaleX(0);
      }
      .bar:nth-of-type(3) {
        transform: rotate(-45deg) translate(4px, 5px);
      }
    }
  `
}

interface MobileMenuButtonProps {
  onClick: () => void
  isOpen: boolean
  menuId: string
  color: ColorName
}

export const MobileMenuButton = ({ onClick, isOpen, menuId, color }: MobileMenuButtonProps) => {
  const label = isOpen ? 'Menü schließen' : 'Menü öffnen'
  return (
    <>
      <button
        id={`button-menu-${menuId}`}
        css={[style.menuButton(color)]}
        aria-label={label}
        aria-haspopup="true"
        aria-controls={`#${menuId}`}
        aria-expanded={isOpen}
        onClick={onClick}
      >
        <span className="inner">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </span>
      </button>
    </>
  )
}

import { SerializedStyles } from '@emotion/core'

type ConditionalStyleRule = [boolean, SerializedStyles]

/**
 * Conditionally apply styles into an array of serialised styles.
 * @param rules Object with the serial
 */
export const cx = (rules: ConditionalStyleRule[]) =>
  rules.filter(([condition]) => condition).map(([, style]) => style)

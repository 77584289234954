import React from 'react'
import { css } from '@emotion/core'
import { desktopBreakpoint, makeMenuLinkStyle, menuLinkStyle } from '../../styles'
import { ThemeColorName } from '../../types'

const style = {
  menuLi: css`
    @media ${desktopBreakpoint} {
      display: inline-block;
      margin-right: 0.5em;
      &:last-of-type {
        margin-right: 0;
      }
    }
  `
}

interface MenuLinkProps {
  color: ThemeColorName
  path: string
  label: string
  isActive: boolean
}
export const MenuLink = ({ color, path, label, isActive }: MenuLinkProps) => (
  <li role="none" css={style.menuLi}>
    <a
      role="menuitem"
      className={isActive ? 'is-active' : ''}
      css={[menuLinkStyle, makeMenuLinkStyle(color)]}
      href={path}
    >
      {label}
    </a>
  </li>
)

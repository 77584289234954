import React from 'react'
import { css } from '@emotion/core'
import { desktopBreakpoint, makeBgStyle, getColor, makeMenuLinkStyle } from '../../styles'

interface ButtonProps {
  path: string
  label: string
  color: ThemeColorName
}

const style = {
  btn: css`
    display: block;
    padding: 0.2em 1.5em;
    color: ${getColor('white')};
    text-decoration: none;
    outline: 0;
    outline-offset: 3px;
    transition: outline 0ms linear 200ms;
    @media ${desktopBreakpoint} {
      display: inline-block;
      width: auto;
    }
    &:hover {
      text-decoration: underline;
    }
  `
}

export const Button = ({ path, label, color }: ButtonProps) => (
  <a href={path} title={label} css={[style.btn, makeBgStyle(color), makeMenuLinkStyle(color)]}>
    {label}
  </a>
)

Button.defaultProps = {
  color: 'grey'
}

import { css } from '@emotion/core'

import { colors } from '.'

export const linkTextLineStyle = css`
  .TextLineBlock {
    transform-origin: center;
    transition: transform 200ms ease-in-out;
  }
  .TextLine {
    transition: outline 0ms linear 100ms;
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
  &:focus .TextLine {
    outline: 3px dashed ${colors.white};
  }
  &:hover .TextLineBlock {
    transform: scale(1.1);
  }
`

import { css, Global } from '@emotion/core'
import React from 'react'

import { Container, Logo, Menu } from '..'
import { desktopBreakpoint, headerHeight, headerHeightDesktop } from '../../styles'

const style = {
  header: css`
    z-index: 9;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    height: ${headerHeight};
    @media ${desktopBreakpoint} {
      padding-top: 2em;
      height: ${headerHeightDesktop};
    }
  `,
  headerMenuOpen: css`
    z-index: 1000;
  `,
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  logo: css``,
  menu: css``,
  globalStyles: css`
    body:not(.has-js) {
      .Header {
        position: relative;
        height: auto !important;
        @media ${desktopBreakpoint} {
          position: fixed;
          height: ${headerHeightDesktop};
        }
      }
    }
  `
}

interface HeaderProps {
  menuIsOpen: boolean
}

export const Header = ({ menuIsOpen }: HeaderProps) => (
  <>
    <Global styles={[style.globalStyles]} />
    <div className="Header" css={[style.header, menuIsOpen ? style.headerMenuOpen : '']}>
      <Container>
        <div css={style.container}>
          <div css={style.logo}>
            <Logo />
          </div>
          <div css={style.menu}>
            <Menu />
          </div>
        </div>
      </Container>
    </div>
  </>
)

import React, { PropsWithChildren } from 'react'
import ErrorBoundaryComponent from 'react-error-boundary'
import { ErrorFallback } from '..'

const handleError = (err: any) => console.error(err)

export const ErrorBoundary = ({ children }: PropsWithChildren<{}>) => (
  <ErrorBoundaryComponent onError={handleError} FallbackComponent={ErrorFallback}>
    {children}
  </ErrorBoundaryComponent>
)

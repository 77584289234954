import React, { AnchorHTMLAttributes, PropsWithChildren } from 'react'
import { cx } from '../../utils/cx'
import { css } from '@emotion/core'

const style = {
  icon: css`
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1em auto;
    padding-left: 1.5em !important;
    display: inline-block;
  `,
  noPadding: css`
    padding: 0 !important;
  `,
  'icon-file': css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5C8.12 15 7 13.88 7 12.5S8.12 10 9.5 10H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z'/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3C/svg%3E");
  `
}

type IconName = 'file'

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  icon?: IconName
  noPadding?: boolean
}

export const Link = ({ children, icon, noPadding, ...props }: PropsWithChildren<LinkProps>) => (
  <a
    {...props}
    css={cx([
      [!!icon, style.icon],
      [!!icon, (style as any)[`icon-${icon}`]],
      [noPadding!, style.noPadding]
    ])}
  >
    {children}
  </a>
)

import { css } from '@emotion/core'
import React, { ReactNode, useContext } from 'react'

import {
  desktopLargeBreakpoint,
  getColor,
  menuLinkStyle,
  makeMenuLinkStyle,
  desktopBreakpoint
} from '../../styles'
import { ThemeColorContext } from '../../contexts'

const style = {
  wrapper: css`
    width: 100%;
  `,
  title: css`
    color: ${getColor('white')};
  `,
  titleLink: css`
    @media ${desktopBreakpoint} {
      margin-left: -0.2em;
    }
  `,
  content: css`
    @media ${desktopLargeBreakpoint} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -1em;
    }
  `,
  contentItem: css`
    @media ${desktopLargeBreakpoint} {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 1em;
      padding-left: 1em;
    }
  `
}

interface SmallTeaserViewProps {
  title: string
  children: ReactNode[]
  path: string
  header?: any
}

export const SmallTeaserView = ({ children, title, path, header }: SmallTeaserViewProps) => {
  const color = useContext(ThemeColorContext)
  return (
    <div css={style.wrapper}>
      <h2 css={style.title}>
        <a
          css={[menuLinkStyle, makeMenuLinkStyle(color), style.titleLink]}
          href={path}
          title={title}
        >
          {title}
        </a>
      </h2>
      {header && header}
      <div css={style.content}>
        {children.map((child, i) => (
          <div key={i} css={style.contentItem}>
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}

import { css } from '@emotion/core'
import React, { PropsWithChildren } from 'react'

import { SlantedText } from '..'

const style = {
  textLineBlock: css`
    position: relative;
    transform-origin: center;
    margin: 0.5rem 0;
    z-index: 2;
  `
}

export const TextLineBlock = (props: PropsWithChildren<{}>) => (
  <div css={style.textLineBlock} className="TextLineBlock">
    <SlantedText>{props.children}</SlantedText>
  </div>
)

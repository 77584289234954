import { css } from '@emotion/core'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'

import { Container, ItemHeading } from '..'
import { desktopBreakpoint } from '../../styles'
import { ContentType, ResponsiveImageData, ThemeColorName } from '../../types'
import { ResponsiveImage } from '../images'

const style = {
  wrapper: css`
    position: relative;
  `,
  imageWrapper: css`
    overflow: hidden;
  `,
  imageInner: css`
    height: 60vw;
    @media ${desktopBreakpoint} {
      height: 45vh;
    }
    overflow: hidden;
    position: relative;
    .parallax-outer {
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  `,
  metaBlock: css`
    position: relative;
    top: -5em;
    left: 0;
    right: 0;
    @media ${desktopBreakpoint} {
      top: -3em;
    }
  `,
  metaBlockInner: css``
}

interface SingleHeaderProps {
  image: ResponsiveImageData
  title: string[]
  date?: string
  color: ThemeColorName
  colorIndex: number
  type: ContentType
  location?: string
}

export const SingleHeader = ({ image, title, date, type, location }: SingleHeaderProps) => {
  return (
    <div css={style.wrapper}>
      {image && (
        <div css={style.imageWrapper}>
          <div css={style.imageInner}>
            <Parallax y={[-40, 40]}>
              <ResponsiveImage image={image} alt={'Beitragsbild: ' + title.join(' ')} />
            </Parallax>
          </div>
        </div>
      )}
      <div css={style.metaBlock}>
        <Container>
          <header css={style.metaBlockInner}>
            <ItemHeading
              title={title}
              titlePrefix={type === 'news' && [date]}
              titleSuffix={type === 'events' && [date, location]}
              type={type}
            />
          </header>
        </Container>
      </div>
    </div>
  )
}

SingleHeader.defaultProps = {
  colorIndex: 0
}

import { ThemeColorName, ColorName } from '../types'

const violet = 'violet'
const red = 'red'
const yellow = 'yellow'
const green = 'green'
const grey = 'grey'
const white = 'white'
const black = 'black'
const bodyColor = 'bodyColor'
const negativeBodyColor = 'negativeBodyColor'

export const colors = {
  [violet]: '#754494',
  [red]: '#E61D48',
  [yellow]: '#F59C00',
  [green]: '#009B77',
  [grey]: '#424241',
  [white]: '#fff',
  [black]: '#000',
  [bodyColor]: '#222',
  [negativeBodyColor]: '#fff'
}

export const colorList: ThemeColorName[] = [violet, yellow, green, grey, red]
const colorListLen = colorList.length
export const getColorFromList = (
  index: number,
  startWith: ThemeColorName
): [ThemeColorName, number] => {
  const indexWithOffset = index + colorList.indexOf(startWith)
  const colorIndex = Math.max(Math.floor(index / colorListLen), 0)
  const indexOfColor = indexWithOffset % colorListLen
  const color = colorList[indexOfColor]
  return [color, colorIndex]
}

export const contentColorMap: {
  [key: string]: ThemeColorName
} = {
  news: violet,
  events: yellow,
  supporters: green
}

export const getColor = (color: ColorName) => colors[color]

/**
 * Get a color association from a map, otherwise use the default.
 */
export const getColorFromMap = (color: ThemeColorName, colorMap: any, def: ColorName) => {
  return colorMap[color] || def
}

/**
 * Get the contrast color for a specific color name
 */
export const getContrastColor = (color: ThemeColorName) =>
  getColorFromMap(
    color,
    {
      white: black
    },
    white
  )

export const logoColors: {
  [key in ThemeColorName]: [ThemeColorName, ThemeColorName][]
} = {
  yellow: [[violet, green]],
  green: [[yellow, grey]],
  grey: [[green, red]],
  red: [[yellow, violet], [grey, violet]],
  violet: [[red, yellow], [red, grey]],
  white: [[green, red]]
}
export const getLogoColors = (color: ThemeColorName, colorIndex: number) =>
  logoColors[color][colorIndex] || logoColors[color][0]
export const headingColorMap: {
  [key in ThemeColorName]: [ThemeColorName, ThemeColorName][]
} = {
  yellow: [[white, green], [violet, white], [white, violet]],
  green: [[grey, white], [grey, yellow], [yellow, grey], [yellow, white]],
  grey: [[white, green], [red, white], [white, red], [green, white]],
  red: [[white, grey], [violet, white], [white, violet], [grey, white]],
  violet: [[white, red], [red, white], [white, grey]],
  white: [[white, violet], [violet, yellow], [yellow, grey], [white, green]]
}

export const getHeadingColor = (color: ThemeColorName, colorIndex: number) => {
  const colorListForColor = headingColorMap[color]
  const colorListLen = colorListForColor.length
  const _colorIndex = colorIndex >= colorListLen ? colorIndex % colorListLen : colorIndex
  return colorListForColor[_colorIndex]
}

import { css } from '@emotion/core'
import { colors, desktopBreakpoint, mobileLargerBreakpoint } from './styles'

export const globalStyles = css`
  html,
  body,
  #___gatsby,
  #___gatsby > div {
    min-height: 100vh;
    display: flex;
    flex-grow: 1;
  }
  #___gatsby > div {
    display: flex;
    flex-direction: column;
  }
  * {
    box-sizing: border-box;
  }
  html,
  body {
    font-family: 'druk text', sans-serif;
    line-height: 1.5;
    font-feature-settings: 'liga', 'kern';
    color: ${colors.bodyColor};
    font-size: 12px;
    @media ${mobileLargerBreakpoint} {
      font-size: 16px;
    }
    @media ${desktopBreakpoint} {
      font-size: 18px;
    }
  }
  .has-open-menu {
    height: 100vh;
    overflow: hidden;
  }
  h1 {
    font-size: 3rem;
  }
  p {
    margin: 0;
  }
  body:not(.has-js) .hide-without-js {
    display: none !important;
  }
  body:not(.has-js) .show-menu-with-js {
    display: flex;
    position: relative;
    @media ${desktopBreakpoint} {
      position: fixed;
    }
  }
`

import { css } from '@emotion/core'
import React, { useContext } from 'react'

import { TextLine, TextLineBlock } from '..'
import config from '../../../config.json'
import { ThemeColorContext, ThemeColorIndexContext, LanguageContext } from '../../contexts'
import { colors, desktopBreakpoint, getLogoColors, linkTextLineStyle } from '../../styles'

const style = {
  logo: css`
    display: block;
    font-size: 1.5em;
    text-decoration: none;
    color: ${colors.white};
    padding-top: 0.5em;
    @media ${desktopBreakpoint} {
      font-size: 2em;
      padding-top: 0;
    }
  `
}

export const Logo = () => {
  const themeColor = useContext(ThemeColorContext)
  const themeColorIndex = useContext(ThemeColorIndexContext)
  const [primaryColor, secondaryColor] = getLogoColors(themeColor, themeColorIndex)
  const language = useContext(LanguageContext)
  const logoLines = {
    de: ['Solidarität', 'verbindet'],
    en: ['Solidarity', 'unites'],
    tr: ['Dayanışma', 'birleştirir']
  }
  const xOffset2Line = language === 'en' ? 70 : 25
  const lines = logoLines[language]
  return (
    <a href={config.basePath} title={config.siteName} css={[style.logo, linkTextLineStyle]}>
      <TextLineBlock>
        <TextLine fontFamily="druk text" spacing={-0.01} size={1} background={primaryColor}>
          {lines[0]}
        </TextLine>
        <TextLine
          fontFamily="druk text wide"
          fontWeight={900}
          size={0.55}
          spacing={-0.025}
          background={secondaryColor}
          xOffset={xOffset2Line}
        >
          {lines[1]}
        </TextLine>
      </TextLineBlock>
    </a>
  )
}

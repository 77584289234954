import React from 'react'

import { Maybe } from '../graphql-types'

/**
 * Used to pass down the state of the mobile menu to the consumer
 */
export const MobileMenuContext = React.createContext<{
  openMobileMenu: Maybe<number>
  setOpenMobileMenu(val: Maybe<number>): void
}>({ openMobileMenu: null, setOpenMobileMenu: () => {} })

import { css } from '@emotion/core'
import React, { useContext } from 'react'

import { TextLine, TextLineBlock } from '..'
import { ThemeColorContext, ThemeColorIndexContext } from '../../contexts'
import { desktopBreakpoint, getHeadingColor, mobileLargerBreakpoint } from '../../styles'
import { ContentType } from '../../types'

const style = {
  heading: css`
    margin: 0 !important;
    font-size: 2rem;
    @media ${mobileLargerBreakpoint} {
      font-size: 2.5rem;
    }
    @media ${desktopBreakpoint} {
      font-size: 3rem;
    }
  `,
  fix: css`
    display: table;
    margin: 0 auto;
  `
}

interface ItemHeadingProps {
  title: string[]
  titlePrefix?: (string | unknown)[] | false
  titleSuffix?: (string | unknown)[] | false
  size?: number
  titleSize?: number
  type?: ContentType
  colorIndex: number
}
export const ItemHeading = ({
  title,
  titlePrefix,
  titleSuffix,
  size,
  titleSize,
  colorIndex: _colorIndex
}: ItemHeadingProps) => {
  const color = useContext(ThemeColorContext)
  const colorIndex = _colorIndex || useContext(ThemeColorIndexContext)
  const titleLen = Math.max(title.length, 2)
  const colors = Array.from(Array(titleLen)).map((_, i) => getHeadingColor(color, colorIndex + i))
  const fixWrap = (content: string, i: number) =>
    content && (
      <div css={style.fix} key={i}>
        <TextLine color={colors[1][0]} background={colors[1][1]} xOffset={15} size={size}>
          {content}
        </TextLine>
      </div>
    )

  return (
    <TextLineBlock>
      {titlePrefix && titlePrefix.map(fixWrap)}
      <h1 css={style.heading}>
        {title.map((title, i) => (
          <TextLine
            color={colors[i][0]}
            background={colors[i][1]}
            size={size! * titleSize!}
            xOffset={(i % 2) * 10}
            key={i}
          >
            {title}
          </TextLine>
        ))}
      </h1>
      {titleSuffix && titleSuffix.map(fixWrap)}
    </TextLineBlock>
  )
}
ItemHeading.defaultProps = {
  size: 1,
  titleSize: 1,
  colorIndex: 0
}

import { css } from '@emotion/core'
import React, { PropsWithChildren } from 'react'

import { mobileLargerBreakpoint } from '../../styles'

const style = {
  wrapper: css`
    padding-left: 1em;
    padding-right: 1em;
    @media ${mobileLargerBreakpoint} {
      padding-left: 2em;
      padding-right: 2em;
    }
  `
}

export const Container = ({ children }: PropsWithChildren<unknown>) => (
  <div css={style.wrapper}>{children}</div>
)

import React, { PropsWithChildren } from 'react'
import { css } from '@emotion/core'
import { typeSkewDeg, desktopBreakpoint } from '../../styles'

const style = {
  slantedText: css`
    display: inline-block;
    transform-origin: center;
  `,
  nonSlantedOnMobile: css`
    @media ${desktopBreakpoint} {
      transform: rotate(-${typeSkewDeg}deg) skewX(-${typeSkewDeg}deg);
    }
  `,
  alwaysSlanted: css`
    transform: rotate(-${typeSkewDeg}deg) skewX(-${typeSkewDeg}deg);
  `
}

interface SlantedTextProps {
  responsive: boolean
}

export const SlantedText = ({ children, responsive }: PropsWithChildren<SlantedTextProps>) => (
  <div css={[style.slantedText, responsive ? style.nonSlantedOnMobile : style.alwaysSlanted]}>
    {children}
  </div>
)

SlantedText.defaultProps = {
  responsive: false
}

import React from 'react'

interface HtmlProps {
  children: string
  [x: string]: any
}

export const Html = ({ children, ...props }: HtmlProps) => (
  <div dangerouslySetInnerHTML={{ __html: children }} {...props} />
)

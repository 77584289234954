import { getColor, getContrastColor, textContentLineLength } from '.'
import { css } from '@emotion/core'
import { ThemeColorName } from '../types'

export const makeContentStyle = (themeColor: ThemeColorName) => {
  const color = getColor(themeColor)
  const contrastColor = getContrastColor(themeColor)

  return css`
    color: ${contrastColor};
    strong {
      font-family: 'druk text wide', sans-serif;
      font-weight: 500;
    }
    p {
      margin-bottom: 1rem;
    }
    h1,
    h2 {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    a {
      color: ${contrastColor};
      text-decoration: none;
      border-bottom: 2px dashed ${contrastColor};
      transition: background-color 0ms linear 200ms, color 0ms linear 200ms;
      padding: 0 0.1em;
      &:hover {
        border-bottom-style: solid;
      }
      &:focus {
        outline: 0;
        background-color: ${contrastColor};
        color: ${color};
        &:hover {
          border-bottom-color: ${color};
        }
      }
    }
  `
}

export const largeText = css`
  font-size: 1.15em;
  font-family: 'druk text';
`

export const textColumnStyle = css`
  max-width: ${textContentLineLength};
  margin: 0 auto;
  font-family: 'druk text wide';
  margin-bottom: 4em;
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`

export const tableStyle = css`
  font-family: 'druk text';
  margin: 2em 0;
  th {
    text-align: left;
    font-family: 'druk text wide';
    font-weight: 700;
  }
  td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5em 0.5em;
    vertical-align: top;
    &:first-of-type {
      padding-left: 0;
    }
  }
`

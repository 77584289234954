export const typeSkewDeg = 10
export const desktopBreakpoint = 'all and (min-width: 1000px)'
export const headerHeight = '3em'
export const headerHeightDesktop = '8em'
export const textContentLineLength = '40em'

export const exclusiveMobileBreakpoint = 'all and (max-width: 1000px)'
export const exclusiveDesktopBreakpoint = 'all and (min-width: 1000px) and (max-width: 1500px)'
export const exclusiveDesktopLargeBreakpoint = 'all and (min-width: 1500px)'

export const desktopLargeBreakpoint = 'all and (min-width: 1500px)'
export const mobileLargerBreakpoint = 'all and (min-width: 500px)'

export const footerHeight = '3.75em'

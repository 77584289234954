function normalizePath(str) {
  return str.replace(/^\//g, '').replace(/\/$/g, '')
}

/**
 * Check if two paths are the same
 */
module.exports = function pathEqual(path1, path2) {
  return normalizePath(path1) === normalizePath(path2)
}

import { css } from '@emotion/core'
import React, { PropsWithChildren } from 'react'

import { ItemHeading, ResponsiveImage } from '..'
import { colors, desktopBreakpoint, linkTextLineStyle } from '../../styles'
import { ResponsiveImageData } from '../../types'

const style = {
  wrapper: css`
    display: block;
    text-decoration: none;
    margin-bottom: 2em;
    border-bottom-width: 0;
    color: ${colors.white};
    @media ${desktopBreakpoint} {
      margin-bottom: 0;
    }
  `,
  imageWrapper: css``,
  contentWrapper: css`
    transform: translate(0%, -33%);
  `,
  title: css``,
  summary: css``
}

interface SmallTeaserProps {
  title: string[]
  path: string
  image: ResponsiveImageData
  suffix?: (string | unknown)[] | false
  prefix?: (string | unknown)[] | false
  colorIndex?: number
}

export const SmallTeaser = ({
  path,
  title,
  image,
  suffix,
  prefix,
  colorIndex
}: PropsWithChildren<SmallTeaserProps>) => (
  <a href={path} css={[style.wrapper, linkTextLineStyle]}>
    <div css={style.imageWrapper}>
      <ResponsiveImage image={image} alt={'Beitragsbild: ' + title.join(' ')} />
    </div>
    <div css={style.contentWrapper}>
      <ItemHeading
        colorIndex={colorIndex}
        title={title}
        titlePrefix={prefix}
        titleSuffix={suffix}
        titleSize={0.6}
      />
    </div>
  </a>
)

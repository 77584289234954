import { chunk } from 'lodash'
import { css } from '@emotion/core'
import React, { ReactNode, useContext } from 'react'

import { ThemeColorContext } from '../../contexts'
import { makeContentStyle, desktopBreakpoint, srOnly } from '../../styles'
import { Container } from '../generic/Container'

const style = {
  wrapper: css`
    margin-top: 2em;
  `,
  title: css`
    @media ${desktopBreakpoint} {
      ${srOnly}
    }
  `,
  content: css``,
  row: css`
    @media ${desktopBreakpoint} {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -1em;
    }
  `,
  contentItem: css`
    margin-bottom: 8em;
    @media ${desktopBreakpoint} {
      margin-bottom: 0;
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 1em;
      padding-left: 1em;
    }
  `
}

interface LargeTeaserViewProps {
  title: string
  children: ReactNode[]
}

export const LargeTeaserView = ({ children, title }: LargeTeaserViewProps) => {
  const themeColor = useContext(ThemeColorContext)
  const rows = chunk(children, 2)
  return (
    <Container>
      <div css={style.wrapper}>
        <h2 css={[style.title, makeContentStyle(themeColor)]}>{title}</h2>
        <div css={style.content}>
          {rows.map((row, i) => (
            <div key={i} css={style.row}>
              {row.map((item, j) => (
                <div key={j} css={style.contentItem}>
                  {item}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

import { css } from '@emotion/core'
import React, { PropsWithChildren, useContext } from 'react'
import { Parallax } from 'react-scroll-parallax'

import { ThemeColorContext } from '../../contexts'
import { desktopBreakpoint, largeText, makeBgStyle } from '../../styles'
import { ResponsiveImageData } from '../../types'
import { cx } from '../../utils/cx'
import { ResponsiveImage } from '../images'

const style = {
  wrapper: css`
    position: relative;
    overflow: hidden;

    @media ${desktopBreakpoint} {
      min-height: 95vh;
    }
  `,

  // bg
  bg: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @media ${desktopBreakpoint} {
      padding-top: 5vmin;
      padding-bottom: 5vmin;
    }
  `,
  //
  // contentWrapper
  contentWrapper: css`
    width: 100%;
    position: relative;
    z-index: 2;
    @media ${desktopBreakpoint} {
      position: absolute;
      width: 50%;
      top: 0;
      bottom: 0;
    }
  `,
  contentWrapperSmall: css`
    @media ${desktopBreakpoint} {
      width: 66%;
    }
  `,
  contentWrapperImageLeft: css`
    right: 0;
  `,
  contentWrapperImageRight: css`
    left: 0;
  `,
  contentWrapperNormal: css`
    bottom: 0;
    @media ${desktopBreakpoint} {
      top: 50%;
      bottom: auto;
    }
  `,

  // content
  content: css`
    z-index: 2;
    top: 0;
    @media ${desktopBreakpoint} {
      width: 80%;
      top: 50%;
      position: absolute;
      transform: translate(0, -50%);
    }
  `,
  contentImageRight: css`
    right: 0;
  `,
  imageWrapper: css`
    width: 100%;
    margin-bottom: -15vmin;
    @media ${desktopBreakpoint} {
      margin-bottom: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 5vmin;
      overflow: hidden;
      width: 60%;
    }
  `,
  imageWrapperImageRight: css`
    right: 0;
    @media ${desktopBreakpoint} {
      left: auto;
    }
  `,
  imageWrapperSmall: css`
    @media ${desktopBreakpoint} {
      width: 50%;
    }
  `
}

interface SectionImageProps {
  imageLeft: boolean
  image?: ResponsiveImageData
  desktopImage?: ResponsiveImageData
  size: 'medium' | 'small'
  alt: string
}

export const SectionImage = ({
  imageLeft,
  image,
  desktopImage,
  children,
  size,
  alt
}: PropsWithChildren<SectionImageProps>) => {
  const x = 20
  const imageParallaxX = imageLeft ? [x * -1, x] : [x, x * -1]
  const color = useContext(ThemeColorContext)
  return (
    <div css={style.wrapper}>
      <div css={[makeBgStyle(color), style.bg]}></div>
      {image && (
        <div
          css={cx([
            [true, style.imageWrapper],
            [!imageLeft, style.imageWrapperImageRight],
            [size === 'small', style.imageWrapperSmall]
          ])}
        >
          <Parallax y={[-15, 33]} x={imageParallaxX} tagOuter="div">
            <ResponsiveImage image={image} desktopImage={desktopImage} alt={alt} />
          </Parallax>
        </div>
      )}
      <div
        css={cx([
          [true, style.contentWrapper],
          [imageLeft, style.contentWrapperImageLeft],
          [!imageLeft, style.contentWrapperImageRight],
          [size === 'small', style.contentWrapperSmall]
        ])}
      >
        <div css={[style.content, imageLeft ? '' : style.contentImageRight, largeText]}>
          {children}
        </div>
      </div>
    </div>
  )
}

SectionImage.defaultProps = {
  size: 'medium'
}

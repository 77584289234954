import { css } from '@emotion/core'
import React, { useContext } from 'react'
import { Parallax } from 'react-scroll-parallax'

import { Html, ItemHeading, ResponsiveImage } from '..'
import { ThemeColorContext } from '../../contexts'
import {
  desktopBreakpoint,
  linkTextLineStyle,
  makeContentStyle,
  mobileLargerBreakpoint,
  textContentLineLength,
  largeText
} from '../../styles'
import { ResponsiveImageData } from '../../types'

const style = {
  wrapper: css`
    display: block;
    text-decoration: none;
    margin-bottom: 4em;
    position: relative;
    @media ${mobileLargerBreakpoint} {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
    }
    @media ${desktopBreakpoint} {
      margin-bottom: 8em;
    }
  `,
  imageWrapper: css`
    position: relative;
    z-index: -3;
    @media ${mobileLargerBreakpoint} {
      margin-right: 2em;
      width: 25vw;
      flex: 1;
    }
    @media ${desktopBreakpoint} {
      position: absolute;
    }
  `,
  contentWrapper: css`
    flex: 2;
    @media ${desktopBreakpoint} {
      padding-left: 33%;
      padding-top: 33%;
    }
  `,
  title: css`
    margin-top: -4em;
    @media ${mobileLargerBreakpoint} {
      margin-left: -5em;
    }
    @media ${desktopBreakpoint} {
      margin-left: 0;
      transform: translateX(-33%);
      margin-top: 0;
    }
  `,
  summary: css`
    max-width: ${textContentLineLength};
    margin-top: 2em;
  `
}

export interface LargeTeaserProps {
  title: string[]
  path: string
  image: ResponsiveImageData
  summary: string
  suffix?: (string | unknown)[] | false
  prefix?: (string | unknown)[] | false
  index: number
}

export const LargeTeaser = ({
  summary,
  path,
  title,
  image,
  prefix,
  suffix,
  index
}: LargeTeaserProps) => {
  const themeColor = useContext(ThemeColorContext)
  return (
    <a href={path} css={[style.wrapper, linkTextLineStyle]}>
      {image && (
        <div css={style.imageWrapper}>
          <Parallax y={[-20, 20]}>
            <ResponsiveImage image={image} alt={'Beitragsbild: ' + title.join(' ')} />
          </Parallax>
        </div>
      )}
      <div css={[style.contentWrapper, makeContentStyle(themeColor)]}>
        <div css={style.title}>
          <ItemHeading
            colorIndex={index % 3}
            titleSuffix={suffix}
            titlePrefix={prefix}
            title={title}
            size={0.75}
          />
        </div>
        <Html css={[style.summary, largeText]}>{summary}</Html>
      </div>
    </a>
  )
}

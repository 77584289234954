import { css } from '@emotion/core'
import React, { PropsWithChildren } from 'react'

import { Container, Html, SectionImage, SlantedText } from '..'
import { desktopBreakpoint, makeContentStyle, srOnly, textContentLineLength } from '../../styles'
import { ThemeColorName, ResponsiveImageData } from '../../types'
import { ItemHeading } from '../type'

const style = {
  // contentInner
  contentInner: css`
    display: inline-block;
    @media ${desktopBreakpoint} {
      max-width: ${textContentLineLength};
      padding: 0;
    }
  `
}

interface SectionProps {
  color: ThemeColorName
  image?: ResponsiveImageData
  desktopImage?: ResponsiveImageData
  content: string
  imageLeft: boolean
  title: string[]
  hideTitle: boolean
  titleSuffix?: (string | unknown)[]
  titlePrefix?: (string | unknown)[]
}

export const HighlightSection = ({
  content,
  imageLeft,
  image,
  desktopImage,
  color,
  children,
  title,
  titleSuffix,
  titlePrefix,
  hideTitle
}: PropsWithChildren<SectionProps>) => {
  return (
    <article>
      <SectionImage
        desktopImage={desktopImage}
        image={image}
        imageLeft={imageLeft}
        alt={title.join(' ')}
      >
        <Container>
          <div css={style.contentInner}>
            {hideTitle ? (
              <h2 css={srOnly}>{title.join(' ')}</h2>
            ) : (
              <ItemHeading title={title} titlePrefix={titlePrefix} titleSuffix={titleSuffix} />
            )}
            <SlantedText responsive={true}>
              <div css={makeContentStyle(color)}>
                <Html>{content}</Html>
              </div>
              {children}
            </SlantedText>
          </div>
        </Container>
      </SectionImage>
    </article>
  )
}

HighlightSection.defaultProps = {
  imageLeft: true,
  hideTitle: true
}

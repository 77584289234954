import { getContrastColor, getColor, desktopBreakpoint } from '.'
import { css } from '@emotion/core'
import { ThemeColorName } from '../types'

export const makeMenuLinkStyle = (themeColor: ThemeColorName) => {
  const contrastColor = getContrastColor(themeColor)
  const color = getColor(themeColor)
  return css`
    color: ${contrastColor};
    &:focus {
      outline: 3px dashed ${contrastColor};
    }
    &.is-active {
      background: ${contrastColor};
      color: ${color};
    }
  `
}

export const menuLinkStyle = css`
  font-size: 1.5em;
  display: block;
  text-align: center;
  margin: 0.25em 2rem;
  text-decoration: none;
  padding: 0.2em;
  outline: 0;
  outline-offset: 3px;
  transition: outline 0ms linear 200ms;
  &:hover {
    text-decoration: underline;
  }
  @media ${desktopBreakpoint} {
    font-size: 1em;
    display: inline-block;
    text-align: left;
    margin: 0;
  }
`

import { css } from '@emotion/core'
import React, { PropsWithChildren } from 'react'

import { colors } from '../../styles'
import { ColorName } from '../../types'

const style = {
  textLine: ({
    fontFamily,
    size,
    background,
    fontWeight,
    fontStyle,
    xOffset,
    color,
    spacing
  }: TextLineProps) => css`
    font-family: ${fontFamily};
    font-size: ${size}em;
    background: ${colors[background]};
    font-weight: ${fontWeight};
    text-transform: uppercase;
    color: ${colors[color]};
    letter-spacing: ${spacing}em;
    font-style: ${fontStyle};
    line-height: 1em;
    display: table;
    height: 1.33em;
    padding: 0 0.33em 0.06em 0.33em;
    margin-top: -0.05em;
    transform-origin: center;
    transform: translateX(${xOffset}%);
    white-space: pre;
    &:first-of-type {
      margin-top: 0;
    }
  `,
  textLineInner: css`
    display: table-cell;
    vertical-align: middle;
  `
}

interface TextLineProps {
  fontFamily: string
  size: number
  background: ColorName
  color: ColorName
  fontWeight: number
  fontStyle: string
  xOffset: number
  css?: any
  spacing: number
}

export const TextLine = (props: PropsWithChildren<TextLineProps>) => (
  <div className="TextLine" css={[style.textLine(props), props.css]}>
    <div css={style.textLineInner}>{props.children}</div>
  </div>
)

TextLine.defaultProps = {
  fontFamily: 'druk text',
  fontWeight: 500,
  size: 1,
  background: 'grey',
  color: 'white',
  fontStyle: 'normal',
  xOffset: 0,
  css: null,
  spacing: 0
}

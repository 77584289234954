import { css, Global } from '@emotion/core'
import React, { PropsWithChildren } from 'react'

import { Header } from '..'
import {
  MobileMenuContext,
  SectionContext,
  ThemeColorContext,
  ThemeColorIndexContext
} from '../../contexts'
import { desktopBreakpoint, headerHeight, headerHeightDesktop, makeBgStyle } from '../../styles'
import { ThemeColorName } from '../../types'

const style = {
  // wrapper
  wrapper: css`
    position: relative;
    flex-grow: 1;
  `,
  wrapperWithOffset: css`
    padding-top: calc(${headerHeight} + 2em);
    @media ${desktopBreakpoint} {
      padding-top: ${headerHeightDesktop};
    }
  `,

  // header wrapper
  headerWrapper: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  headerWrapperOpenMenu: css`
    overflow-y: scroll;
    min-height: 101vh;
  `,
  headerWrapperNotOpenMenu: css`
    clip: rect(auto, auto, auto, auto);
  `,

  contentWrapper: css`
    position: relative;
    z-index: 2;
  `,
  globalStyles: css`
    body:not(.has-js) {
      .Section-Wrapper--0.Section-Wrapper--with-offset {
        padding-top: 0;
        @media ${desktopBreakpoint} {
          padding-top: ${headerHeightDesktop};
        }
      }
      .Section-HeaderWrapper {
        display: none;
        @media ${desktopBreakpoint} {
          display: block;
        }
      }
      .Section-HeaderWrapper--0 {
        display: block;
        position: relative;
        height: auto;
        @media ${desktopBreakpoint} {
          position: absolute;
        }
      }
    }
  `
}
interface SectionProps {
  color: ThemeColorName
  headerOffset: boolean
  renderBg: boolean
  index: number
  colorIndex: number
}

export const Section = ({
  renderBg,
  headerOffset,
  color,
  children,
  index,
  colorIndex
}: PropsWithChildren<SectionProps>) => (
  <>
    <Global styles={[style.globalStyles]} />
    <SectionContext.Provider value={index}>
      <ThemeColorContext.Provider value={color}>
        <ThemeColorIndexContext.Provider value={colorIndex}>
          <MobileMenuContext.Consumer>
            {({ openMobileMenu }) => {
              const isOpenMenu = openMobileMenu === index
              return (
                <div
                  className={`Section-Wrapper Section-Wrapper--${index} ${
                    headerOffset ? 'Section-Wrapper--with-offset' : ''
                  }`}
                  css={[
                    style.wrapper,
                    headerOffset ? style.wrapperWithOffset : '',
                    renderBg ? makeBgStyle(color) : ''
                  ]}
                >
                  <div
                    className={`Section-HeaderWrapper Section-HeaderWrapper--${index}`}
                    css={[
                      style.headerWrapper,
                      isOpenMenu ? style.headerWrapperOpenMenu : style.headerWrapperNotOpenMenu
                    ]}
                  >
                    <Header menuIsOpen={isOpenMenu} />
                  </div>
                  <div css={style.contentWrapper}>{children}</div>
                </div>
              )
            }}
          </MobileMenuContext.Consumer>
        </ThemeColorIndexContext.Provider>
      </ThemeColorContext.Provider>
    </SectionContext.Provider>
  </>
)

Section.defaultProps = {
  renderBg: false,
  headerOffset: true,
  colorIndex: 0
}
